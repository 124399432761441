var Stations = [
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Lusail",
    station_name_en: "Lusail QNB",
    station_name_ar: "QNB لوسيل",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Qatar University",
    station_name_en: "Qatar University",
    station_name_ar: "جامعة قطر",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Legtaifiya",
    station_name_en: "Legtaifiya",
    station_name_ar: "لقطيفية",
    station_line: ["Red", "Orange","Pink",],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Katara",
    station_name_en: "Katara",
    station_name_ar: "كتارا",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Al Qassar",
    station_name_en: "Al Qassar",
    station_name_ar: "القصار",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "DECC",
    station_name_en: "DECC",
    station_name_ar: "مركز المعارض",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "West Bay",
    station_name_en: "West Bay QatarEnergy",
    station_name_ar: "الخليج الغربي قطر للطاقة",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Corniche",
    station_name_en: "Corniche",
    station_name_ar: "الكورنيش",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Al Bidda",
    station_name_en: "Al Bidda",
    station_name_ar: "البدع",
    station_line: ["Red", "Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Msheireb",
    station_name_en: "Msheireb",
    station_name_ar: "مشيرب",
    station_line: ["Red", "Green", "Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Doha Al Jadeda",
    station_name_en: "Al Doha Al Jadeda",
    station_name_ar: "الدوحة الجديدة",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Umm Ghuwailina",
    station_name_en: "Umm Ghuwailina",
    station_name_ar: "أم غويلينة",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Matar Al Qadeem",
    station_name_en: "Al Matar Al Qadeem",
    station_name_ar: "المطار القديم",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Oqba Ibn Nafie",
    station_name_en: "Oqba Ibn Nafie",
    station_name_ar: "عقبة بن نافع",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Free Zone",
    station_name_en: "Free Zone",
    station_name_ar: "المنطقة الحرة",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Ras Bu Fontas",
    station_name_en: "Ras Bu Fontas",
    station_name_ar: "راس بوفنطاس",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Wakra",
    station_name_en: "Al Wakra",
    station_name_ar: "الوكرة",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Hamad Intl Airport T1",
    station_name_en: "Hamad Intl Airport T1",
    station_name_ar: "مطار حمد الدولي مبنى 1",
    station_line: ["Red"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Riffa",
    station_name_en: "Al Riffa Mall of Qatar",
    station_name_ar: "الرفاع قطر مول",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Education City",
    station_name_en: "Education City",
    station_name_ar: "المدينة التعليمية",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Qatar National Library",
    station_name_en: "Qatar National Library",
    station_name_ar: "مكتبة قطر الوطنية",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Al Shaqab",
    station_name_en: "Al Shaqab",
    station_name_ar: "الشقب",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Al Rayyan Al Qadeem",
    station_name_en: "Al Rayyan Al Qadeem",
    station_name_ar: "الريان القديم",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Messila",
    station_name_en: "Al Messila",
    station_name_ar: "المسيلة",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Hamad Hospital",
    station_name_en: "Hamad Hospital",
    station_name_ar: "مستشفى حمد",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "The White Palace",
    station_name_en: "The White Palace",
    station_name_ar: "القصر الابيض",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Mansoura",
    station_name_en: "Al Mansoura",
    station_name_ar: "المنصورة",
    station_line: ["Green"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Aziziyah",
    station_name_en: "Al Aziziyah",
    station_name_ar: "العزيزية",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: true,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Sport City",
    station_name_en: "Sport City",
    station_name_ar: "المدينة الرياضية",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Al Waab",
    station_name_en: "Al Waab QLM",
    station_name_ar: "QLM الوعب",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Sudan",
    station_name_en: "Al Sudan",
    station_name_ar: "السودان",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Joaan",
    station_name_en: "Joaan",
    station_name_ar: "جوعان",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Sadd",
    station_name_en: "Al Sadd",
    station_name_ar: "السد",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Bin Mahmoud",
    station_name_en: "Bin Mahmoud",
    station_name_ar: "بن محمود",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "Souq Waqif",
    station_name_en: "Souq Waqif",
    station_name_ar: "سوق واقف",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_shelter_ops: false,
    station_name: "National Museum",
    station_name_en: "National Museum",
    station_name_ar: "المتحف الوطني",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: true,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Ras Bu Abboud",
    station_name_en: "Ras Bu Abboud",
    station_name_ar: "راس بو عبود",
    station_line: ["Gold"],
    station_network: 'Metro'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Marina",
    station_name_en: "Marina – South",
    station_name_ar: "المارينا - جنوب",
    station_line: ["Orange", "Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Marina Promenade",
    station_name_en: "Marina – Central",
    station_name_ar: "المارينا - سنترال",
    station_line: ["Orange", "Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Yacht Club",
    station_name_en: "Yacht Club",
    station_name_ar: "نادي اليخوت",
    station_line: ["Orange", "Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Esplanade",
    station_name_en: "Marina – North",
    station_name_ar: "المارينا - شمال",
    station_line: ["Orange", "Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: true,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Lusail Central",
    station_name_en: "Tarfat – South",
    station_name_ar: "طرفة - جنوب",
    station_line: ["Orange", "Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Tarfat North",
    station_name_en: "Tarfat – North",
    station_name_ar: "طرفة - شمال",
    station_line: ["Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Wadi",
    station_name_en: "Wadi",
    station_name_ar: "وادي",
    station_line: ["Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Seef Lusail South",
    station_name_en: "Seef Lusail - South",
    station_name_ar: "سيف لوسيل - جنوب",
    station_line: ["Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Seef Lusail North",
    station_name_en: "Seef Lusail - North",
    station_name_ar: "سيف لوسيل - شمال",
    station_line: ["Pink"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: true,
    station_service_hours: true,
    station_name: "Energy City South",
    station_name_en: "Al Wessil",
    station_name_ar: "الوسل",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Naifa",
    station_name_en: "Naifa",
    station_name_ar: "نيفا",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Fox Hills South",
    station_name_en: "Fox Hills - South",
    station_name_ar: "جبل ثعيلب - جنوب",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: true,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Downtown Lusail",
    station_name_en: "Downtown Lusail",
    station_name_ar: "داون تاون لوسيل",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Khayl Street",
    station_name_en: "Al Khayl Street",
    station_name_ar: "شارع الخيل",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Fox Hills North",
    station_name_en: "Fox Hills - North",
    station_name_ar: "جبل ثعيلب - شمال",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Cresent Park North",
    station_name_en: "Cresent Park - North",
    station_name_ar: "الحديقة الهلالية - شمال",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Rawdat Lusail",
    station_name_en: "Rawdat Lusail",
    station_name_ar: "روضة لوسيل",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Erkiyah",
    station_name_en: "Erkiyah",
    station_name_ar: "أركية",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Lusail Stadium",
    station_name_en: "Lusail Stadium",
    station_name_ar: "استاد لوسيل",
    station_line: ["Orange"],
    station_network: 'Tram'
    },
    {
    station_retail_info: false,
    station_poi: false,
    station_metrolink: false,
    station_metroexpress: false,
    station_service_hours: true,
    station_name: "Al Yasmeen",
    station_name_en: "Al Yasmeen",
    station_name_ar: "الياسمين",
    station_line: ["Orange"],
    station_network: 'Tram'
    },

   ];
export default Stations;