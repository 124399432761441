// extracted by mini-css-extract-plugin
export var lineIcon = "stationContainer-module--lineIcon--+jeKO";
export var lineInfoIcon = "stationContainer-module--lineInfoIcon--bVy76";
export var shelter_ops_img = "stationContainer-module--shelter_ops_img--lVI+I";
export var stationContainer = "stationContainer-module--stationContainer--vhArY";
export var stationInfoContainer = "stationContainer-module--stationInfoContainer--TNtJ3";
export var stationInfoName = "stationContainer-module--stationInfoName--qk5sD";
export var stationName = "stationContainer-module--stationName--LPXmT";
export var stationNameArXs = "stationContainer-module--stationNameArXs--gACpJ";
export var stationNameEn = "stationContainer-module--stationNameEn--TgqQ9";
export var stationNameEnXs = "stationContainer-module--stationNameEnXs--NKTl4";
export var xxs = "stationContainer-module--xxs--vdXcW";